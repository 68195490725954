import './libs/0-loadCSS.js';
import './libs/0ff-supports.js';
import './libs/0plugins.js';
import './libs/0polyfills.js';
import * as anime from './libs/3anime.min.js';
import './libs/3slick.min.js';
import './libs/4screenfull.js';
import './libs/5select2.full.min.js';
import './libs/6select2_fr.js';
import './libs/7select2searchplaceholder.js';
import * as autosize from './libs/8autosize.min.js';

String.prototype.removeAccents = function () {
  return this.replace(/[áàãâä]/gi, 'a')
    .replace(/[éè¨ê]/gi, 'e')
    .replace(/[íìïî]/gi, 'i')
    .replace(/[óòöôõ]/gi, 'o')
    .replace(/[úùüû]/gi, 'u')
    .replace(/[ç]/gi, 'c')
    .replace(/[ñ]/gi, 'n');
  // .replace(/[^a-zA-Z0-9]/g," ");
};
(function ($) {
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Variables
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var w = window,
    d = document,
    $window = $(w),
    $html = $('html'),
    $body = $('body'),
    $header = $('#header'),
    $main = $('#main'),
    $nav = $('#nav'),
    $menuTrigger = $('#menuTrigger'),
    $nav_inner = $nav.find('.nav_inner'),
    windowH,
    windowW,
    scrollT,
    sauvScroll = 0,
    $adminBar = $('#wpadminbar'),
    has_adminBar = $body.hasClass('admin-bar'),
    adminBar_h = 0,
    serveur_test = d.location.hostname == 'localhost',
    requeting = false;

  w.setTimeout(function () {
    $html.addClass('is-siteInit');
  }, 300);

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Dimensions
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function update_dimensions() {
    windowH = $window.height();
    windowW = $window.width();
    update_adminbar();
    update_scroll();
  }
  function update_scroll() {
    scrollT = $window.scrollTop();
  }
  update_dimensions();

  function update_adminbar() {
    $adminBar =
      !$adminBar.length && has_adminBar ? $('#wpadminbar') : $adminBar;
    adminBar_h =
      $adminBar.length && $adminBar.is(':visible') ? $adminBar.height() : 0;
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Helper functions
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //...

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //rts
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // if(!support.objectFit) ffclass.add(html,'no-objectfit');
  // if(!support.objectPosition) ffclass.add(html,'no-objectposition');
  fallback_object_fit();
  function fallback_object_fit($cont) {
    if (support.objectFit) return;

    var $fits = ($cont || $body).find('.cv-img');
    $fits.each(function (idx, fit) {
      var $fit = $(fit);
      var fitClass = $fit.hasClass('cv-contain') ? 'cv-bg-contain' : 'cv-bg';
      var srcFit = fit.hasAttribute('src') ? fit.getAttribute('src') : false;
      if (!srcFit)
        srcFit = fit.hasAttribute('data-src')
          ? fit.getAttribute('data-src')
          : false;
      if (!srcFit) return;
      var $replaceFit = $('<div>');
      $replaceFit
        .addClass(fit.className)
        .addClass(fitClass)
        .removeClass('cv-img')
        .removeClass('cv-contain')
        .css('background-image', 'url(' + srcFit + ')');
      $fit.before($replaceFit);
      $fit.addClass('srOnly').removeClass('cv-img');
    });
  }

  var transformProp;
  (function () {
    var style = document.createElement('a').style;
    var prop;
    if (style[(prop = 'webkitTransform')] !== undefined) {
      transformProp = prop;
    }
    if (style[(prop = 'msTransform')] !== undefined) {
      transformProp = prop;
    }
    if (style[(prop = 'transform')] !== undefined) {
      transformProp = prop;
    }
  })();

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Scroll jusqu'a un element
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function scrollToEl($el) {
    $('html, body').animate(
      {
        scrollTop:
          $el.offset().top -
          $nav.outerHeight() -
          parseInt($html.css('margin-top')),
      },
      300
    );
  }

  var $directElToScroll = $body.find('.js-scroll-to-me').first();
  if ($directElToScroll.length) scrollToEl($directElToScroll);

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Menu principal
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var $nav = $('#nav');
  var $navItems = $nav.find('.nav_i');
  var $navLinks = $nav.find('.nav_l');
  var $navLinksSubnav = $navLinks.filter(':not(:last-child)');
  var $navClosers = $body.find('.js-close-menu');

  $navLinksSubnav.on('click', function (event) {
    event.preventDefault();
    var $this = $(this);
    var $parent = $this.closest('.nav_i');
    var $itemOpen = $nav.find('.nav_i.is-open');
    //Si aucun ouvert on ouvre en animant hauteur à partir de 0 jusqu'a hauteur sous menu
    //Si fermeture on ferme en animant hauteur jusqu'a 0
    //Si un déja ouvert on anime la hauteur depuis celui ouvert jusqu'a celle du sous-menu
    if ($parent.hasClass('is-open')) {
      //Fermeture
      close_menu($parent);
    } else if ($itemOpen.length) {
      //Changement
      toggle_menu($parent, $itemOpen);
    } else {
      //Ouverture
      open_menu($parent);
    }
  });

  function close_menu($parent) {
    $parent = $parent && $parent.length ? $parent : $nav.find('.nav_i.is-open');
    var $subMenu = $parent && $parent.length ? $parent.find('.nav_sub') : [];
    if (!$parent || !$parent.length || !$subMenu.length) return;

    if (is_mobile()) {
      $html.removeClass('has-menu-open');
      $parent.removeClass('is-open');
      $subMenu.removeAttr('style');
    } else {
      anime({
        targets: $subMenu.get(0),
        height: 0,
        duration: 250,
        easing: 'easeInOutQuad', //'linear'
        begin: function (anim) {
          $html.removeClass('has-menu-open');
        },
        complete: function (anim) {
          $parent.removeClass('is-open');
          $subMenu.removeAttr('style');
        },
      });
    }
  }

  function open_menu($parent) {
    var $subMenu = $parent && $parent.length ? $parent.find('.nav_sub') : [];
    if (!$parent || !$parent.length || !$subMenu.length) return;

    if (is_mobile()) {
      $parent.addClass('is-open');
      $html.addClass('has-menu-open');
      $subMenu.removeAttr('style');
      menu_after_openned($subMenu);
    } else {
      anime({
        targets: $subMenu.get(0),
        height: [0, $subMenu.get(0).scrollHeight],
        duration: 250,
        easing: 'easeInOutQuad', //'linear'
        begin: function (anim) {
          $parent.addClass('is-open');
          $html.addClass('has-menu-open');
        },
        complete: function (anim) {
          $subMenu.removeAttr('style');
          // $html.addClass('has-menu-open');
          menu_after_openned($subMenu);
        },
      });
    }
  }

  function toggle_menu($parent, $itemOpen) {
    var $subMenu = $parent && $parent.length ? $parent.find('.nav_sub') : [];
    var $itemOpen =
      $itemOpen && $itemOpen.length ? $itemOpen : $nav.find('.nav_i.is-open');
    if (
      !$parent ||
      !$parent.length ||
      !$subMenu.length ||
      !$itemOpen ||
      !$itemOpen.length
    )
      return;

    if (is_mobile()) {
      $itemOpen.removeClass('is-open').removeAttr('style');
      $parent.addClass('is-open');
      $subMenu.removeAttr('style');
      menu_after_openned($subMenu);
    } else {
      anime({
        targets: $subMenu.get(0),
        height: [
          $itemOpen.find('.nav_sub').outerHeight(),
          $subMenu.get(0).scrollHeight,
        ],
        duration: 250,
        easing: 'easeInOutQuad', //'linear'
        begin: function (anim) {
          $itemOpen.removeClass('is-open').removeAttr('style');
          $parent.addClass('is-open');
        },
        complete: function (anim) {
          $subMenu.removeAttr('style');
          menu_after_openned($subMenu);
        },
      });
    }
  }

  $navClosers.on('click', function (event) {
    event.preventDefault();
    close_menu();
  });

  function menu_after_openned($subMenu) {
    $subMenu.find('input').first().focus();
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Header (menu ou filtres)
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function update_header() {
    update_adminbar();
    if ($nav.get(0).getBoundingClientRect().top <= adminBar_h) {
      if (!$html.hasClass('is-nav-fixed')) {
        $html.addClass('is-nav-fixed');
      }
    } else {
      if ($html.hasClass('is-nav-fixed')) {
        $html.removeClass('is-nav-fixed');
      }
    }
  }
  update_header();

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Menu mobile
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var idMenuTimeOut = false;
  $menuTrigger.on('click', function (event) {
    event.preventDefault();
    menu_mobile_toggle();
  });

  function is_mobile() {
    return $menuTrigger.is(':visible');
  }

  function menu_mobile_toggle() {
    if ($html.hasClass('has-menuMobileOpen')) {
      menu_mobile_close();
    } else {
      menu_mobile_open();
    }
  }

  function menu_mobile_open() {
    sauvScroll = scrollT;
    idMenuTimeOut = w.setTimeout(function () {
      $html.addClass('is-scrollBlockedMobile');
    }, 300);
    $html.addClass('has-menuMobileOpen');
  }

  function menu_mobile_close() {
    if (idMenuTimeOut) w.clearTimeout(idMenuTimeOut);
    $html.removeClass('is-scrollBlockedMobile');
    w.scrollTo(0, sauvScroll);
    w.setTimeout(function () {
      $html.removeClass('has-menuMobileOpen');
      $html.removeClass('has-menuMobileOpen-spe');
      $html.removeClass('has-menuMobileOpen-account');
      $html.removeClass('has-menuMobileOpen-login');
      $html.removeClass('has-menuMobileOpen-search');
    }, 50);
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Menu mobile : compte et recherche
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  $header.on('click', '.js-nav-mobile-account', function (event) {
    event.preventDefault();
    menu_mobile_spe('account');
  });
  $header.on('click', '.js-nav-mobile-login', function (event) {
    event.preventDefault();
    menu_mobile_spe('login');
  });
  $header.on('click', '.js-nav-mobile-search', function (event) {
    event.preventDefault();
    menu_mobile_spe('search');
  });

  function menu_mobile_spe(type) {
    if (!$html.hasClass('has-menuMobileOpen')) {
      $html.addClass('has-menuMobileOpen-' + type);
      $html.addClass('has-menuMobileOpen-spe');
      menu_mobile_open();
    } else if (!$html.hasClass('has-menuMobileOpen-' + type)) {
      $html.removeClass('has-menuMobileOpen-account');
      $html.removeClass('has-menuMobileOpen-login');
      $html.removeClass('has-menuMobileOpen-search');
      $html.addClass('has-menuMobileOpen-' + type);
      $html.addClass('has-menuMobileOpen-spe');
    } else {
      menu_mobile_close();
    }
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Accordéons Trombinoscope
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function trombis_acc_init() {
    var $trombis_acc = $main.find('.trombi_acc');
    if ($trombis_acc.length) {
      $trombis_acc.each(function (index, el) {
        trombi_acc_setup($(el));
      });
    }
  }
  trombis_acc_init();

  function trombi_acc_setup($trombi) {
    $trombi.addClass('is-closed').find('.trombi_cont').hide();
    $trombi.on('click', '.trombi_btn', function (event) {
      event.preventDefault();
      trombi_acc_close_siblings($trombi);
      trombi_acc_toggle($trombi);
    });
  }

  function trombi_acc_toggle($trombi) {
    $trombi.toggleClass('is-closed').find('.trombi_cont').slideToggle(400);
  }

  function trombi_acc_close_siblings($trombi) {
    $trombi.siblings('.trombi').each(function (index, el) {
      trombi_acc_close($(el));
    });
  }

  function trombi_acc_close($trombi) {
    if (!$trombi.hasClass('is-closed'))
      $trombi.addClass('is-closed').find('.trombi_cont').slideUp(400);
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Accordéons
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function accs_init() {
    var $accs = $main.find('.acc');
    if ($accs.length) {
      $accs.each(function (index, el) {
        acc_setup($(el));
      });
    }
  }
  accs_init();

  function acc_setup($acc) {
    $acc.addClass('is-closed').find('.acc_cont').hide();
    $acc.on('click', '.acc_btn', function (event) {
      event.preventDefault();
      acc_close_siblings($acc);
      acc_toggle($acc);
    });
  }

  function acc_toggle($acc) {
    $acc.toggleClass('is-closed').find('.acc_cont').slideToggle(400);
  }

  function acc_close_siblings($acc) {
    $acc.siblings('.acc').each(function (index, el) {
      acc_close($(el));
    });
  }

  function acc_close($acc) {
    if (!$acc.hasClass('is-closed'))
      $acc.addClass('is-closed').find('.acc_cont').slideUp(400);
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Accordéons mobile
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  $main.find('.js-mobile-acc').on('click', function (event) {
    event.preventDefault();
    var $btn = $(this);
    if (!$btn.is(':visible') || $btn.css('pointer-event') == 'none') return;
    if ($btn.is(':last-child')) $btn.parent().parent().toggleClass('is-open');
    else $btn.parent().toggleClass('is-open');
  });

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Pagination select
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function pagination_to_select($cont) {
    ($cont || $main)
      .find('.pagination:not(.has-select) ul.is-mobile')
      .each(function (index, el) {
        pagination_to_select_init(el);
      });
  }
  pagination_to_select();

  function pagination_to_select_init(paginationUl) {
    var $paginationUl = $(paginationUl);
    var $pagination = $paginationUl.closest('.pagination');
    var $paginationItems = $paginationUl.find('li');
    var $paginationItemsPages = $paginationItems.filter(function (idx, el) {
      var $link = $(el).children('a,span');
      return (
        $link.length && ($link.is('span') || !$link.is('.pagination_arrow'))
      );
    });
    var $select = $('<select>').addClass('js-select-redirect');
    var $liSelect = $('<li class="is-select">');
    var $lastArrow = $paginationUl.find('li:last-child > .pagination_arrow');
    $paginationItemsPages.each(function (idx, item) {
      var $item = $(item);
      var $link = $item.children('a,span');
      var url = $link.is('a') ? $link.attr('href') : w.location.href;
      var $option = $('<option>')
        .attr('value', url)
        .text('Page ' + $link.text());
      if ($item.hasClass('is-active')) $option.attr('selected', true);
      $select.append($option);
    });
    $liSelect.append($('<span class="select">').append($select));
    if ($lastArrow.length) {
      $lastArrow.parent('li').before($liSelect);
    } else {
      $paginationUl.append($liSelect);
    }
    $paginationItemsPages.remove();
    $pagination.addClass('has-select');
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Trombi
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function trombis_init() {
    var $trombis = $main.find('.trombi');
    if ($trombis.length) {
      $trombis.each(function (index, el) {
        trombi_setup($(el));
      });
    }
  }
  trombis_init();

  function trombi_setup($trombi) {
    var $trombiLinks = $trombi.find('[href^="#trombi-bio-"]');
    if (!$trombiLinks.length) return;
    var $trombiMods = $trombi.find('.trombi_i');
    var $fiche = $('<div class="trombi_fiche gridTr_i gridTr_i-full"></div>');
    $fiche.hide();
    // var next_trombi_to_open = false;
    $trombiLinks.on('click', function (event) {
      event.preventDefault();
      var $this = $(this);
      var $trombiMod = $this.closest('.trombi_i');
      var bio = $trombiMod.find($this.attr('href')).html();
      if ($trombiMod.hasClass('is-active')) {
        $trombi.removeClass('has-oneOpen');
        trombi_close($fiche, $trombiMod);
      } else if ($fiche.is(':visible')) {
        $fiche.slideUp(400, function () {
          trombi_open($fiche, $trombiMods, $trombiMod, bio);
        });
      } else {
        $trombi.addClass('has-oneOpen');
        trombi_open($fiche, $trombiMods, $trombiMod, bio);
      }
    });
    $trombi.on('click', '.js-trombi-close', function (event) {
      event.preventDefault();
      $trombi.removeClass('has-oneOpen');
      trombi_close($fiche, false, $trombiMods);
    });
  }

  function trombi_open($fiche, $trombiMods, $trombiMod, bio) {
    $fiche.html(bio);
    $trombiMod
      .addClass('is-active')
      .siblings('.trombi_i')
      .removeClass('is-active');
    $trombiMods
      .filter(function (idx, el) {
        return (
          Math.abs(
            el.getBoundingClientRect().top -
              $trombiMod.get(0).getBoundingClientRect().top
          ) < 10
        );
      })
      .last()
      .after($fiche);
    $fiche.slideDown(400);
  }

  function trombi_close($fiche, $trombiMod, $trombiMods) {
    if ($trombiMod) $trombiMod.removeClass('is-active');
    else if ($trombiMods) $trombiMods.removeClass('is-active');
    $fiche.slideUp(400);
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Sliders
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var $fullscreenEl = false;
  var $sliders = $main.find('.slider');
  $sliders.each(function (index, el) {
    var $slider = $(el);
    var sliderWrapper = $slider.closest('.pageSlider_wrapper');
    var $slides = $slider.find('.slider_i');
    var is_big = $slider.hasClass('slider-big');
    if ($slides.length < 2) return;

    var $sliderNav = $('<div class="slider_nav"></div>');
    $slider.append($sliderNav);
    if (!is_big) {
      $slider.find('.pageSlider_lgd').addClass('srOnly');
    }

    $slider.slick({
      autoplay: is_big,
      autoplaySpeed: 3000,
      appendArrows: $sliderNav,
      prevArrow:
        '<button><span class="srOnly">Previous</span><span class="icon-arrow-left"></span></button>',
      nextArrow:
        '<button><span class="srOnly">Next</span><span class="icon-arrow-right"></span></button>',
      // initialSlide: 0,
      slide: '.slider_i',
    });

    if (!is_big && typeof screenfull !== 'undefined' && screenfull.enabled) {
      var $sliderFS = $(
        '<button><span class="srOnly">Fullscreen</span><span class="icon-fullscreen"></span></button>'
      );
      $sliderNav.append($sliderFS);
      $sliderFS.on('click', function (event) {
        event.preventDefault();
        $slider.addClass('is-fullscreen-loading');
        $slider.addClass('has-loader');
        $slider.addClass('has-loader-white');
        $slider.addClass('is-loading');
        screenfull.toggle($slider.get(0));
        setTimeout(function () {
          var currentSlide = $slider.slick('slickCurrentSlide');
          $slider.slick('unslick');
          $slider.find('img').each(function () {
            $(this)
              .attr({ 'data-sizes': $(this).attr('sizes') })
              .removeAttr('sizes');
          });
          $slider.slick({
            autoplay: is_big,
            autoplaySpeed: 3000,
            appendArrows: $sliderNav,
            prevArrow:
              '<button><span class="srOnly">Previous</span><span class="icon-arrow-left"></span></button>',
            nextArrow:
              '<button><span class="srOnly">Next</span><span class="icon-arrow-right"></span></button>',
            initialSlide: currentSlide || 0,
            slide: '.slider_i',
          });
          setTimeout(function () {
            $slider.removeClass('is-fullscreen-loading');
            $slider.removeClass('has-loader');
            $slider.removeClass('has-loader-white');
            $slider.removeClass('is-loading');
          }, 500);
        }, 800);
      });
    }

    if (!is_big) {
      // Légende
      var $sliderLgd = $('<div class="pageSlider_lgd"></div>');
      sliderWrapper.append($sliderLgd);
      $sliderLgd.html($slides.eq(0).find('.pageSlider_lgd').html());
      $slider.on(
        'beforeChange',
        function (event, slick, currentSlide, nextSlide) {
          var $slideLgd = slick.$slides.eq(nextSlide).find('.pageSlider_lgd');
          // console.log(slick.$slides.eq(nextSlide));
          $sliderLgd.html($slideLgd.length ? $slideLgd.html() : '');
        }
      );
    }
  });

  if (typeof screenfull !== 'undefined' && screenfull.enabled) {
    screenfull.onchange(function () {
      // var $elToRefresh = false;
      // if(screenfull.element && $(screenfull.element).is('.slider')) {
      //   $fullscreenEl = $(screenfull.element);
      //   $fullscreenEl.addClass('is-fullscreen-active');
      //   if($fullscreenEl[0].slick) {
      //     $elToRefresh = $fullscreenEl;
      //   }
      // } else if($fullscreenEl) {
      //   $fullscreenEl.removeClass('is-fullscreen-active');
      //   $fullscreenEl.slick('setPosition');
      //   if($fullscreenEl[0].slick) {
      //     $elToRefresh = $fullscreenEl;
      //   }
      //   $fullscreenEl = false;
      // }
      // if($elToRefresh) {
      //   $elToRefresh.slick('setPosition');
      //   setTimeout(function() {
      //     $elToRefresh.slick('setPosition');
      //   },500);
      // }
      if (screenfull.isFullscreen) {
        if (screenfull.element && $(screenfull.element).is('.slider')) {
          $fullscreenEl = $(screenfull.element);
          $fullscreenEl.addClass('is-fullscreen-active');
        }
      } else if ($fullscreenEl) {
        $fullscreenEl.addClass('is-fullscreen-loading');
        $fullscreenEl.removeClass('is-fullscreen-active');
        $fullscreenEl.addClass('has-loader');
        $fullscreenEl.addClass('has-loader-white');
        $fullscreenEl.addClass('is-loading');
        setTimeout(function () {
          var currentSlide = $fullscreenEl.slick('slickCurrentSlide');
          $fullscreenEl.slick('unslick');
          $fullscreenEl.find('img').each(function () {
            $(this)
              .attr({ sizes: $(this).attr('data-sizes') })
              .removeAttr('data-sizes');
          });
          $fullscreenEl.slick({
            autoplay: $fullscreenEl.hasClass('slider-big'),
            autoplaySpeed: 3000,
            appendArrows: $fullscreenEl.find('.slider_nav'),
            prevArrow:
              '<button><span class="srOnly">Previous</span><span class="icon-arrow-left"></span></button>',
            nextArrow:
              '<button><span class="srOnly">Next</span><span class="icon-arrow-right"></span></button>',
            initialSlide: currentSlide || 0,
            slide: '.slider_i',
          });
          setTimeout(function () {
            $fullscreenEl.removeClass('is-fullscreen-loading');
            $fullscreenEl.removeClass('has-loader');
            $fullscreenEl.removeClass('has-loader-white');
            $fullscreenEl.removeClass('is-loading');
          }, 500);
        }, 800);
      }
    });
  }

  $('.partenaires.is-style-slider').slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
    slide: 'div',
    infinite: true,
    centerMode: true,
    variableWidth: true,
    // pauseOnHover: false,
    // pauseOnFocus: false,
  });

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Partager un contenu
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  $main.find('.page_share button').on('click', function (event) {
    event.preventDefault();
    $(this).closest('.page_share').toggleClass('is-clicked');
  });

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Montrer / masquer des éléments avec des radio / checkboxes
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  show_hide($main.find('[data-show-id]'));
  $main.find('[data-show]').each(function (index, trigger) {
    var $trigger = $(trigger);
    show_change(trigger);
    $trigger.on('change', function (event) {
      show_change(this, true);
    });
  });

  function show_change(trigger, live) {
    var $trigger = $(trigger);
    var targets =
      $trigger.is('select') &&
      trigger.options[trigger.selectedIndex] &&
      trigger.options[trigger.selectedIndex].hasAttribute('data-show-val')
        ? trigger.options[trigger.selectedIndex].getAttribute('data-show-val')
        : $trigger.data('show');
    var targets_ids = targets ? targets.split(',') : false;
    var $targets = targets_ids
      ? $main.find(
          '[data-show-id="' + targets_ids.join('"],[data-show-id="') + '"]'
        )
      : [];

    if ($trigger.is('select')) {
      $trigger.find('option').each(function (idx, el) {
        if (el.getAttribute('value') != trigger.value) {
          var opTargets = el.hasAttribute('data-show-val')
            ? el.getAttribute('data-show-val')
            : false;
          var opTargets_ids = opTargets ? opTargets.split(',') : false;
          var $opTargets = opTargets_ids
            ? $main.find(
                '[data-show-id="' +
                  opTargets_ids.join('"],[data-show-id="') +
                  '"]'
              )
            : [];
          show_hide($opTargets);
        }
      });
      show_show($targets);
    } else if ($trigger.is(':checked')) {
      if (live && $trigger.attr('type') == 'radio') {
        $trigger
          .closest('form')
          .find(
            'input[type="radio"][name="' +
              $trigger.attr('name') +
              '"][data-show]'
          )
          .not(':checked')
          .each(function (index, el) {
            show_change(el, true);
          });
      }
      // $targets.removeClass('is-hide');
      show_show($targets);
    } else if (live) {
      // $targets.addClass('is-hide');
      show_hide($targets);
    }
  }

  function show_hide($els) {
    if (!$els.length) return;
    $els.each(function (index, el) {
      var $el = $(el);
      $el
        .addClass('is-section-disabled')
        .find('input,select,textarea')
        .attr('disabled', 'true');
      if ($el.data('show-type') != 'disabled') $el.addClass('is-hide');
    });
  }
  function show_show($els) {
    if (!$els.length) return;
    $els.each(function (index, el) {
      var $el = $(el);
      $el
        .removeClass('is-section-disabled')
        .removeClass('is-hide')
        .find('input,select,textarea')
        .removeAttr('disabled');
      $el
        .find('[data-show-id].is-hide')
        .find('input,select,textarea')
        .attr('disabled', 'true');
    });
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Stripe
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var stripe = false;
  var stripeElements = false;
  var stripeCard = false;
  var paymentInProgress = false;
  if ($('#stripe-card-element').length) {
    init_stripe();
  }

  // https://stripe.github.io/elements-examples/fr/
  // https://github.com/stripe/elements-examples/blob/master/js/example2.js
  // https://stripe.com/docs/stripe-js/reference#element-types
  function init_stripe() {
    var stripeArgs = {
      hidePostalCode: true,
      // hideIcon: true,
      // iconStyle: 'solid',
      style: {
        base: {
          // Add your base input styles here. For example:
          fontSize: '16px',
          color: '#4D4D4D',
          '::placeholder': {
            color: '#9B9B9B',
          },
        },
      },
    };
    stripe = Stripe(constants.stripe_pk);
    stripeElements = stripe.elements();
    stripeCard = stripeElements.create('card', stripeArgs);
    stripeCard.mount('#stripe-card-element');
    stripeCard.addEventListener('change', function (event) {
      var $stripeErrors = $('#stripe-card-errors');
      if (event.error) {
        $stripeErrors.text(event.error.message);
      } else {
        $stripeErrors.text('');
      }
    });
  }

  function stripe_payment() {
    if (paymentInProgress) return;
    paymentInProgress = true;
    var $formDon = $('#form-don');
    $('#btn-don-payment')
      .addClass('has-loader')
      .addClass('has-loader-s')
      .addClass('has-loader-white')
      .addClass('is-loading');
    var stripeBillingDetails = {
      name:
        $formDon.find('[name="fname"]').val() +
        ' ' +
        $formDon.find('[name="lname"]').val(),
      address: {
        city: $formDon.find('[name="city"]').val(),
        country: $formDon.find('[name="country"]').val(), // code du pays sur 2 lettres
        line1: $formDon.find('[name="address"]').val(),
        //line2       : ,
        postal_code: $formDon.find('[name="postal"]').val(),
        //state       : ,
      },
      email: $formDon.find('[name="email"]').val(),
    };

    var stripeBillingPhone = $formDon.find('[name="phone"]').val();
    if (stripeBillingPhone.length > 0) {
      stripeBillingDetails.phone = stripeBillingPhone;
    }

    stripe
      .handleCardPayment($formDon.data('secret'), stripeCard, {
        payment_method_data: {
          billing_details: stripeBillingDetails,
        },
      })
      .then(function (result) {
        if (result.error) {
          var $stripeErrors = $('#stripe-card-errors');
          $stripeErrors.text(result.error.message);
          paymentInProgress = false; //Si réussi on ne remet pas la variable a false, pas besoin car rechargement page
          $('#btn-don-payment')
            .removeClass('has-loader')
            .removeClass('has-loader-s')
            .removeClass('has-loader-white')
            .removeClass('is-loading');
        } else {
          $formDon.prepend(
            '<input type="hidden" name="intent" value="' +
              $formDon.data('intent') +
              '">'
          );
          $formDon.submit();
        }
      });
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Formulaire don
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var $formDon = $('#form-don');
  //On bloc la soummission, on soumet que si clic sur bouton
  $formDon.on('submit', function (event) {
    var $activeSection = $formDon
      .find('.js-tabs-section:not(.is-hide)')
      .first();
    var $nextSectionBtn = $activeSection.find('.js-tabs-go').last();
    if ($nextSectionBtn.length) {
      event.preventDefault();
      $nextSectionBtn.trigger('click');
    } else if (
      $activeSection.find('#stripe-card-element').length &&
      !$formDon.find('[name=intent]').length
    ) {
      event.preventDefault();
      // var isValid = true;
      // var $inputsSection = $activeSection.find('[name]').not('[disabled]');
      // $inputsSection.each(function(index, el) {
      //   var $input = $(el);
      //   if(!el.checkValidity()) {
      //     $input.closest('p,ul').addClass('is-error').find('.form_error').remove();
      //     $input.closest('p,ul').append('<span class="form_error">'+el.validationMessage+'</span>');
      //     isValid = false;
      //   }
      // });
      // if(isValid)
      stripe_payment();
    }
  });

  function update_don_intent() {
    if ($formDon.data('updating-intent')) {
      return;
    }
    $formDon.data('updating-intent', true);
    // Créé ou met à jour l’intention de paiement
    $.ajax({
      url: url.ajax,
      type: 'POST',
      dataType: 'json',
      data: {
        action: 'get_stripe_payment_intent',
        amount: bind_form_don_vars.totaltopay,
        intent: $formDon.data('intent'),
        nonce: $formDon.find('[name=nonce]').val(),
        'g-recaptcha-response': $formDon
          .find('[name=g-recaptcha-response]')
          .val(),
        // Metadata
        destination: $formDon.find('[name=destination]:checked').val(),
        frequence: $formDon.find('[name=frequence]:checked').val(),
        fondation: $formDon.find('[name=fondation]').val(),
        projet: $formDon.find('[name=projet_chosen]').val(),
        anonyme: $formDon.find('[name=anonyme]:checked').val(),
        ami: $formDon.find('[name=ami]:checked').val(),
        type_ami: $formDon.find('[name=type_ami]:checked').val(),
        renouvellement: $formDon.find('[name=renouvellement]:checked').val(),
        gender: $formDon.find('[name=gender]').val(),
        fname: $formDon.find('[name=fname]').val(),
        lname: $formDon.find('[name=lname]').val(),
        entreprise: $formDon.find('[name=entreprise]').val(),
        entreprise_statut: $formDon.find('[name=entreprise_statut]').val(),
        newsletter_suscribe: $formDon
          .find('[name=newsletter_suscribe]:checked')
          .val(),
        // Seconde personne
        second_gender: $formDon.find('[name=second_gender]').val(),
        second_fname: $formDon.find('[name=second_fname]').val(),
        second_lname: $formDon.find('[name=second_lname]').val(),
        second_email: $formDon.find('[name=second_email]').val(),
        second_newsletter_suscribe: $formDon
          .find('[name=second_newsletter_suscribe]:checked')
          .val(),
      },
    })
      .done(function (data) {
        if (data) {
          $formDon.data('intent', data.intent);
          $formDon.data('secret', data.secret);
        }
      })
      .always(function (data) {
        $formDon.data('updating-intent', false);
      });
  }

  $formDon.on('click', '.js-tabs-go', function () {
    update_don_intent();
  });

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Formulaire sondage
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

  // Limitation du nombre de réponses
  // On enregistre l’ordre des éléments sélectionnés,
  // et on décoche le choix le plus ancien si on essaie d’en sélectionner plus que le maximum autorisé
  var $sondForm = $main.find('#form-sondage'),
    sondChoix = $sondForm.data('choix'),
    $sondCheckB = $sondForm.find('input[name^="propositions[]"]'),
    $lastChecked = [],
    $sondButton = $sondForm.find('.btnForm');

  $sondButton.on('click', function () {
    $sondCheckB
      .first()
      .closest('.is-error')
      .removeClass('is-error')
      .find('.form_error')
      .remove();
    var sondError =
      sondChoix < 2
        ? 'Vous devez sélectionner ' + sondChoix + ' proposition'
        : 'Vous devez sélectionner ' + sondChoix + ' propositions';
    $sondCheckB
      .get(0)
      .setCustomValidity(
        $sondCheckB.filter(':checked').length != sondChoix ? sondError : ''
      );
  });

  $sondCheckB.on('change', function () {
    var $this = $(this),
      $checked = $sondCheckB.filter(':checked');
    if ($checked.length > sondChoix) {
      var firstChecked = $lastChecked.shift();
      $(firstChecked).prop('checked', false);
    }
    if ($(this).is(':checked')) {
      $lastChecked.push(this);
    } else {
      $lastChecked = $lastChecked.filter(function (checkB) {
        return !$(checkB).is($this);
      });
    }
  });

  // Modale
  var $sondMore = $sondForm.find('.modP_more');
  var $modal = $body.find('.modal');

  $sondMore.on('click', function () {
    var $modP = $(this).closest('.modP'),
      $modP_modal = $modP.find('.modP_modal');

    $html.addClass('has-modal');
    $modal.removeClass('is-hide').html($modP_modal.html());

    return false;
  });

  $modal.on('click', function (e) {
    var $target = $(e.target);
    if (
      !$modal.is(e.target) &&
      !$target.hasClass('modP_modal-close') &&
      !$target.parent().hasClass('modP_modal-close')
    ) {
      return;
    }
    $html.removeClass('has-modal');
    $(this).addClass('is-hide');
  });

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Tabs formulaires
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  $main.find('.js-tabs').each(function (index, tabCont) {
    var $tabCont = $(tabCont);
    var ajaxForm = tabCont.hasAttribute('data-ajax-form');
    var tunelForm = tabCont.hasAttribute('data-tunel-form');
    var historyTabs =
      tabCont.hasAttribute('data-tabs-history') && support.history;
    var $triggers = $tabCont.find('.js-tabs-go');
    var $triggersHead = $tabCont.find('.tabs .js-tabs-go');
    var $sections = $tabCont.find('.js-tabs-section');
    $triggers.each(function (idx, el) {
      var $this = $(this);
      var sectionID = $this.data('section-id')
        ? '#' + $this.data('section-id')
        : $this.attr('href');
      var $section = sectionID ? $(sectionID) : [];
      if (!$section.length && $this.closest('.tabs').length)
        $this.closest('li').addClass('is-disabled');
    });
    $triggers.on('click', function (event) {
      event.preventDefault();
      var $this = $(this);
      var isHead = $this.closest('.tabs').length;
      var sectionID = $this.data('section-id')
        ? '#' + $this.data('section-id')
        : $this.attr('href');
      var $section = sectionID ? $(sectionID) : [];
      var $activeAfter = isHead
        ? $this.closest('li').nextAll('.is-active')
        : []; //on peut seulement revenir en arrière
      var isValid = true;
      var $activeSection = $tabCont.find('.js-tabs-section:not(.is-hide)');
      var isNext = $section.prevAll('.js-tabs-section:not(.is-hide)').length;
      var toValid = isNext && ajaxForm;

      if (!$section.length || (isHead && !$activeAfter.length && tunelForm))
        return;

      if (toValid) {
        // Vérifie la validité de tous les champs de la section
        isValid = inputs_validity($activeSection);
      }

      if (!isValid) return;

      var gotoTab = function () {
        $triggersHead.closest('li').removeClass('is-active');
        // $this.closest('li').addClass('is-active');
        $triggersHead
          .filter(
            '[href="' +
              sectionID +
              '"],[data-section-id="' +
              sectionID.substring(1) +
              '"]'
          )
          .closest('li')
          .addClass('is-active');
        $sections.addClass('is-hide');
        $section.removeClass('is-hide');
        scrollToEl($tabCont);
        if (historyTabs && $this.attr('href')) {
          history.replaceState({}, '', $this.attr('href'));
        }
      };

      if (toValid) {
        // Vérifie la validité des emails de la section
        checkmails_ajax($activeSection, gotoTab);
      } else {
        gotoTab();
      }
    });
  });

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Valider un formulaire
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function inputs_validity($container, justCheck) {
    var $inputs = $container.find('[name]').not('[disabled]');
    if ($container.get(0).hasAttribute('data-validity-noempty'))
      $inputs = $inputs.filter(function (idx, el) {
        return el.value && el.value != '';
      });
    var isValid = true;
    var $firstInvalid = false;
    if (!$inputs.length) return isValid;
    // https://developer.mozilla.org/en-US/docs/Learn/HTML/Forms/Form_validation
    $inputs.each(function (index, el) {
      var $input = $(el);
      var $inputParent = $input.closest('p,ul,article');
      if (typeof el.checkValidity === 'function' && !el.checkValidity()) {
        if (!justCheck) {
          $inputParent.addClass('is-error').find('.form_error').remove();
          $inputParent.append(
            '<span class="form_error">' + el.validationMessage + '</span>'
          );
        }
        isValid = false;
        if (!$firstInvalid) $firstInvalid = $inputParent;
        // console.log(el.name,el.value,el.checkValidity());
        // console.log('validationMessage : '+el.validationMessage);
        // console.log(el.validity); //valueMissing: true, typeMismatch: false, patternMismatch: false, tooLong: false, tooShort: false, rangeUnderflow: false, rangeOverflow: false, stepMismatch: false, badInput: false, customError: false
      }
    });
    if (!justCheck && $firstInvalid) {
      scrollToEl($firstInvalid);
    }
    return isValid;
  }

  $main.find('.js-form').on('submit', function (event) {
    var isValid = inputs_validity($(this));
    if (!isValid) event.preventDefault();
  });

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Formulaire aide : eligibilite
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var $formAide1 = $('#form-aide-1-form');
  if ($formAide1.length) {
    form_aide_check_elibibilite_init();
  }

  function form_aide_check_elibibilite_init() {
    var $eligibleSections = $formAide1.find('[data-eligible-section]');
    var $ineligibleSections = $formAide1.find('[data-ineligible-section]');

    $formAide1
      .find('select,[type="radio"]')
      .on('change', form_aide_check_elibibilite_changing);
    $formAide1
      .find('input:not([type="radio"])')
      .on('input', form_aide_check_elibibilite_changing);
    form_aide_check_elibibilite_changing();

    function form_aide_check_elibibilite_changing() {
      form_aide_check_elibibilite_hide($eligibleSections);
      form_aide_check_elibibilite_hide($ineligibleSections);
      form_aide_check_elibibilite_pre_change();
      form_aide_check_elibibilite_change();
    }

    function form_aide_check_elibibilite_pre_change() {
      $formAide1
        .find('option[data-aide-ineligible-changer]')
        .each(function (index, el) {
          var $option = $(el);
          var queryEl = $option.data('aide-ineligible-changer').split('|');
          var $targetSelect = queryEl[0]
            ? $formAide1.find('select[name="' + queryEl[0] + '"]')
            : [];
          var $targetOption =
            $targetSelect.length && queryEl[1]
              ? $targetSelect.find('option[value="' + queryEl[1] + '"]')
              : [];
          if ($option.is(':selected')) {
            $targetOption.removeAttr('data-aide-ineligible');
          } else {
            $targetOption.attr('data-aide-ineligible', '1');
          }
        });
    }

    function form_aide_check_elibibilite_change() {
      var isValid = inputs_validity($formAide1, true);
      var valType = $formAide1.find('[name="concerne"]:checked').val();
      var $ineligibleOptionsSelected = $formAide1.find(
        'select:not([disabled]) option[data-aide-ineligible]:selected'
      );
      if (!isValid) {
        form_aide_check_elibibilite_hide($eligibleSections);
        form_aide_check_elibibilite_hide($ineligibleSections);
      } else if ($ineligibleOptionsSelected.length) {
        form_aide_check_elibibilite_hide($eligibleSections);
        form_aide_check_elibibilite_show($ineligibleSections, valType);
      } else {
        form_aide_check_elibibilite_show($eligibleSections, valType);
        form_aide_check_elibibilite_hide($ineligibleSections);
      }
    }

    function form_aide_check_elibibilite_show($section, type) {
      $section
        .filter(function (index, el) {
          return (
            !el.hasAttribute('data-eligible-section-type') ||
            !type ||
            el.getAttribute('data-eligible-section-type') == type
          );
        })
        .removeClass('is-hide')
        .find('[name]')
        .removeAttr('disabled');
    }
    function form_aide_check_elibibilite_hide($section) {
      $section.addClass('is-hide').find('[name]').attr('disabled', 'true');
    }
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Demande d'aide readonly si closed
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  $main
    .find('.js-auto-readonly')
    .find('[name]')
    .attr('readonly', 'readonly')
    .filter('select,[type="checkbox"],[type="radio"]')
    .attr('disabled', 'disabled');
  $main
    .find('.js-auto-readonly')
    .find('.file_remove,.file_add,.js-ajax-file')
    .remove();
  $main
    .find('.file_label:last-child,.file_desc:last-child')
    .after('Aucun fichier');

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Labels form
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  $main
    .find('.form_field')
    .find('input,textarea,select')
    .each(function (index, input) {
      var $input = $(input);
      var isSelect = $input.is('select');
      field_blur(input);
      if (isSelect) {
        $(input).on('change', function (event) {
          field_blur(this);
        });
      } else {
        $(input)
          .on('focus', function (event) {
            field_focus(this);
          })
          .on('blur', function (event) {
            field_blur(this);
          });
      }
    });

  function field_blur(input) {
    var $input = $(input);
    var $parent = $input.closest('.form_field');
    var isValid = input.validity.valid;
    var len = input.value.length;
    var isSelect = $input.is('select');

    if (!isSelect && !isValid && !len) $input.val(''); //Fix number no value if invalid
    if (len) $parent.addClass('is-full');
    else $parent.removeClass('is-full');
    $parent.removeClass('is-focus');
  }

  function field_focus(input) {
    var $input = $(input);
    var $parent = $input.closest('.form_field');
    $parent.addClass('is-focus');
  }

  $main
    .find('.form')
    .find('input,textarea,select')
    .on('focus', function (event) {
      $(this)
        .closest('.is-error')
        .removeClass('is-error')
        .find('.form_error')
        .remove();
    });

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Textarea autosize
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  autosize($main.find('.form textarea'));

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Select stylisé focus/bur
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  $body
    .find('.select select')
    .on('focus', function (event) {
      $(this).parent().addClass('is-open');
    })
    .on('blur', function (event) {
      $(this).parent().removeClass('is-open');
    });

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Montant du don, calcul et maj dernier onglet
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var bind_form_don_vars = {
    amount: 0,
    destination: false,
    destination_format: false,
    frequence: false,
    don: true,
    ami: false,
    ami_prix: 0,
    ami_annuel: false,
    impot: 66,
  };

  if ($formDon.length) {
    bind_form_don_init();
  }

  function bind_form_don_init() {
    var $allInputs = $formDon.find('input[name],textarea[name],select[name]');
    $allInputs
      .not('[type="checkbox"],[type="radio"],select')
      .on('input', bind_form_don_change);
    $allInputs.filter('select').on('change', bind_form_don_change);
    $allInputs
      .filter('[type="checkbox"],[type="radio"]')
      .on('click', bind_form_don_change);
    $allInputs.each(function (index, el) {
      bind_form_don_change(false, el);
    });
  }

  function bind_form_don_change(event, input) {
    var input = input || this;
    var $input = $(input);
    if (
      $.inArray($input.attr('name'), [
        'don',
        'montant',
        'montant_libre',
        'destination',
        'frequence',
        'ami',
        'type_ami',
        'renouvellement',
        'projet_chosen',
        'fondation',
        'impot',
      ]) === -1
    )
      return;
    if ($input.attr('type') == 'radio' && !$input.is(':checked')) return;
    if (
      $input.closest('[data-show-id].is-hide').length ||
      $input.closest('[data-show-id].is-section-disabled').length
    )
      return;
    // console.log(input.name,input.value,input.checked);
    bind_form_don_modifs(input);
  }

  function bind_form_don_modifs(input) {
    var change_impots = false;
    var change_don = false;
    var change_total = false;
    var change_ami = false;

    if ($.inArray(input.name, ['don', 'montant', 'montant_libre']) !== -1) {
      bind_form_don_vars.don = get_bind_form_checkbox('don');
      bind_form_don_vars.amount = bind_form_don_vars.don
        ? get_bind_form_radio('montant', true, false, 'montant_libre')
        : 0;
      change_impots = true;
      change_don = true;
      change_total = true;
    }
    if (
      $.inArray(input.name, ['destination', 'projet_chosen', 'fondation']) !==
      -1
    ) {
      bind_form_don_vars.destination = get_bind_form_radio('destination');
      bind_form_don_vars.destination_format = get_bind_form_destination_format(
        bind_form_don_vars.destination
      );
      bind_form_don_vars.frequence =
        bind_form_don_vars.destination != 'projet'
          ? get_bind_form_radio('frequence', false, true)
          : false;
      change_don = true;
    }
    if ($.inArray(input.name, ['frequence']) !== -1) {
      bind_form_don_vars.frequence = get_bind_form_radio(
        'frequence',
        false,
        true
      );
      change_don = true;
    }
    if ($.inArray(input.name, ['ami']) !== -1) {
      bind_form_don_vars.ami = get_bind_form_checkbox('ami');
      bind_form_don_vars.ami_prix = bind_form_don_vars.ami
        ? get_bind_form_radio('type_ami', true, false)
        : 0;
      bind_form_don_vars.ami_annuel = bind_form_don_vars.ami
        ? get_bind_form_checkbox('renouvellement')
        : false;
      change_impots = true;
      change_ami = true;
      change_total = true;
    }
    if ($.inArray(input.name, ['type_ami']) !== -1) {
      bind_form_don_vars.ami_prix = get_bind_form_radio(
        'type_ami',
        true,
        false
      );
      change_impots = true;
      change_ami = true;
      change_total = true;
    }
    if ($.inArray(input.name, ['renouvellement']) !== -1) {
      bind_form_don_vars.ami_annuel = get_bind_form_checkbox('renouvellement');
      change_ami = true;
    }
    if ($.inArray(input.name, ['impot']) !== -1) {
      bind_form_don_vars.impot = get_bind_form_radio('impot', true);
      change_impots = true;
    }

    // console.log("amount : "+bind_form_don_vars.amount);
    // console.log("destination : "+bind_form_don_vars.destination);
    // console.log("destination_format : "+bind_form_don_vars.destination_format);
    // console.log("frequence : "+bind_form_don_vars.frequence);
    // console.log("ami : "+bind_form_don_vars.ami);
    // console.log("ami_prix : "+bind_form_don_vars.ami_prix);
    // console.log("ami_annuel : "+bind_form_don_vars.ami_annuel);

    if (change_impots) {
      get_bind_form_change_impot();
    }

    if (change_don) {
      get_bind_form_change_don();
    }

    if (change_total) {
      get_bind_form_change_total();
    }

    if (change_ami) {
      get_bind_form_change_ami();
    }
  }

  function getSelectedOption(select) {
    if (!select || !select.value || select.selectedIndex == -1) return false;
    return select.options[select.selectedIndex];
  }

  function getSelectedOptionText(select) {
    var selectedOption = getSelectedOption(select);
    if (!selectedOption) return '';
    return selectedOption.text != ''
      ? selectedOption.text
      : selectedOption.hasAttribute('title')
      ? selectedOption.getAttribute('title')
      : '';
  }

  function get_bind_form_destination_format(type) {
    var destination = false;
    if (type == 'fondation') {
      destination = getSelectedOptionText(
        $formDon.find('[name="fondation"]').get(0)
      );
    } else if (type == 'projet') {
      destination = getSelectedOptionText(
        $formDon.find('[name="projet_chosen"]').get(0)
      );
    }
    if (!destination) {
      destination = $formDon
        .find('[name="destination"]')
        .filter(':checked')
        .next('span')
        .text();
    }
    return destination;
  }

  function get_bind_form_checkbox(name) {
    return $formDon
      .find('[name="' + name + '"]')
      .first()
      .is(':checked');
  }

  function get_bind_form_radio(name, isNumber, span, other) {
    var val = false;
    if (other) {
      val = $formDon
        .find('[name="' + other + '"]')
        .first()
        .val();
      val = isNumber ? parseInt(val) : val;
    }
    if (!other || !val) {
      $formDon.find('[name="' + name + '"]').each(function (index, radio) {
        if (radio.checked) {
          if (span) {
            val = $(radio).next('span').text();
          } else if ($(radio).data('value')) {
            val = $(radio).data('value');
          } else {
            val = radio.value;
          }
          val = isNumber ? parseInt(val) : val;
        }
      });
    }
    return val;
  }

  function get_bind_form_change_impot() {
    var $calculator = $formDon.find('.js-impot-calculator');
    var total = bind_form_don_vars.amount + bind_form_don_vars.ami_prix;
    var reduc = Math.floor((total * bind_form_don_vars.impot) / 100);
    $calculator.find('.js-impot-pc').text(bind_form_don_vars.impot);
    $calculator.find('.js-impot-don').text(total - reduc);
    $calculator.find('.js-impot-defisc').text(reduc);
    $formDon.find('.js-don-total').text(total);
  }

  function get_bind_form_change_don() {
    var $htmlInsert = '';
    $htmlInsert += '<table>';
    if (bind_form_don_vars.amount) {
      $htmlInsert +=
        '<tr><th scope="row">Montant</th><td>' +
        bind_form_don_vars.amount +
        '€</td></tr>';
      $htmlInsert += bind_form_don_vars.destination_format
        ? '<tr><th scope="row">Destination</th><td>' +
          bind_form_don_vars.destination_format +
          '</td></tr>'
        : '';
      $htmlInsert +=
        '<tr><th scope="row">Fréquence</th><td>' +
        (bind_form_don_vars.frequence
          ? bind_form_don_vars.frequence
          : 'Don ponctuel') +
        '</td></tr>';
    } else {
      $htmlInsert += '<tr><td>Vous ne souhaitez pas faire de don</td></tr>';
    }
    $htmlInsert += '</table>';
    $('#form-don-recap-don').find('table').remove();
    $('#form-don-recap-don').append($htmlInsert);
    $formDon.find('.js-don').text(bind_form_don_vars.amount);
  }

  function get_bind_form_change_total() {
    bind_form_don_vars.totaltopay =
      bind_form_don_vars.amount + bind_form_don_vars.ami_prix;
    $formDon.find('.js-impot-price').text(bind_form_don_vars.totaltopay);
  }

  function get_bind_form_change_ami() {
    var $htmlInsert = '';
    $htmlInsert += '<table>';
    if (bind_form_don_vars.ami && bind_form_don_vars.ami_prix) {
      $htmlInsert +=
        '<tr><td colspan="2">Vous souhaitez devenir ami de la Sauvegarde</td></tr>';
      $htmlInsert +=
        '<tr><th scope="row">Montant</th><td>' +
        bind_form_don_vars.ami_prix +
        '€/an</td></tr>';
      $htmlInsert +=
        '<tr><th scope="row">Fréquence</th><td>' +
        (bind_form_don_vars.ami_annuel ? 'Don annuel' : 'Adhésion ponctuelle') +
        '</td></tr>';
    } else {
      $htmlInsert +=
        '<tr><td>Vous ne souhaitez pas devenir ami de la Sauvegarde</td></tr>';
    }
    $htmlInsert += '</table>';
    $('#form-don-recap-ami').find('table').remove();
    $('#form-don-recap-ami').append($htmlInsert);
    $formDon.find('.js-adhesion').text(bind_form_don_vars.ami_prix);
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Don radio / libre
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  if ($formDon.length) {
    init_amount_don();
  }

  function init_amount_don() {
    var $amountLibreInput = $formDon.find('[name="montant_libre"]');
    var $amountLibreRadios = $formDon.find('[name="montant"]');
    $amountLibreInput
      .on('input', function (event) {
        if ($amountLibreInput.val() != '') {
          $amountLibreRadios.closest('p,ul').addClass('txt-trans-2');
          $amountLibreInput.closest('p,ul').removeClass('txt-trans-2');
        } else {
          $amountLibreRadios.closest('p,ul').removeClass('txt-trans-2');
          $amountLibreInput.closest('p,ul').addClass('txt-trans-2');
        }
      })
      .trigger('input');
    $amountLibreRadios.on('click', function (event) {
      $amountLibreRadios.closest('p,ul').removeClass('txt-trans-2');
      $amountLibreInput.val('').closest('p,ul').addClass('txt-trans-2');
    });
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Vérification email Ajax
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function checkmails_ajax($cont, callback) {
    if (requeting) {
      return;
    }
    if (typeof callback !== 'function') {
      return;
    }
    var $emails = $cont.find(
      'input[type=email]:visible:not(:disabled):not([readonly])'
    );
    if ($emails.length === 0) {
      callback();
      return;
    }
    var emails = $emails
      .map(function () {
        return this.value;
      })
      .get()
      .join();
    if (!emails) {
      return;
    }
    //$cont.find('.form_error').remove();
    requeting = true;
    var $firstInvalid = false,
      $button = $cont.find('button:last');
    $button
      .addClass('has-loader')
      .addClass('has-loader-s')
      .addClass('has-loader-white')
      .addClass('is-loading');
    $.ajax({
      url: url.ajax,
      type: 'POST',
      dataType: 'json',
      data: { action: 'checkmails', emails: emails },
    })
      .done(function (data) {
        if (data.success) {
          //close_menu();
          callback();
        } else if (data.emails) {
          $.each(data.emails, function (index, email) {
            if (email.success) {
              return;
            }
            var $input = $emails.eq(index),
              $inputParent = $input.closest('p,ul,article');
            if ($inputParent.length === 0) {
              return;
            }
            $inputParent.addClass('is-error').find('.form_error').remove();
            $inputParent.append(
              '<span class="form_error">' + email.message + '</span>'
            );
            if (!$firstInvalid) $firstInvalid = $inputParent;
          });
          if ($firstInvalid) {
            scrollToEl($firstInvalid);
          }
        }
      })
      // Échec non-bloquant, si la vérification ne fonctionne pas, on poursuit malgré tout
      .fail(function () {
        //$cont.append('<span class="form_error form_error-m">Erreur, veuillez essayer de nouveau.</span>');
        callback();
      })
      .always(function () {
        requeting = false;
        $button
          .removeClass('has-loader')
          .removeClass('has-loader-s')
          .removeClass('has-loader-white')
          .removeClass('is-loading');
        // console.log("complete");
      });
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Login Ajax
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  $body.find('.js-login').each(function (index, container) {
    var $cont = $(container);
    var is_form = $cont.is('form');
    var $formToSubmit = [];
    var toRedirect = $cont.get(0).hasAttribute('data-login-refresh')
      ? $cont.find('[name="redirect_to"]').val()
      : false;
    var $login = is_form
      ? $cont.find('[name="log"]')
      : $cont.find('[name="login_ajax"]');
    var $pass = is_form
      ? $cont.find('[name="pwd"]')
      : $cont.find('[name="pass_ajax"]');
    var $btn = is_form
      ? $cont.find('[type="submit"]')
      : $cont.find('.js-login-submit');
    if ($cont.get(0).hasAttribute('data-login-submit')) {
      $formToSubmit = is_form ? $cont : $cont.closest('form');
    }

    if (!$login.length || !$pass.length || !$btn.length) return;

    if (is_form) {
      $cont.on('submit', function (event) {
        event.preventDefault();
        login_ajax($cont, $login, $pass, $formToSubmit, toRedirect);
      });
    } else {
      $cont.on('keydown', function (event) {
        if (event.which == 13) {
          login_ajax($cont, $login, $pass, $formToSubmit, toRedirect);
          return false;
        }
      });
      $btn.on('click', function (event) {
        event.preventDefault();
        login_ajax($cont, $login, $pass, $formToSubmit, toRedirect);
      });
    }
  });

  function login_ajax($cont, $login, $pass, $formToSubmit, toRedirect) {
    var username = $login.val();
    var pass = $pass.val();
    if (requeting) {
      return;
    }
    if (!username) {
      $login.focus();
      return;
    }
    if (!pass) {
      $pass.focus();
      return;
    }
    $cont.find('.form_error').remove();
    requeting = true;
    $cont
      .find('button')
      .addClass('has-loader')
      .addClass('has-loader-s')
      .addClass('has-loader-white')
      .addClass('is-loading');
    $.ajax({
      url: url.ajax,
      type: 'POST',
      dataType: 'json',
      data: { action: 'loginajx', logn: username, pswd: pass },
    })
      .done(function (data) {
        if (data.success) {
          //close_menu();
          login_update_form($cont, data);
          if (data.menu) $('#menu-account-section').html(data.menu);
          if ($formToSubmit.length) $formToSubmit.submit();
          else if (toRedirect) w.location.href = toRedirect;
        } else {
          $cont.append(
            '<span class="form_error form_error-m">' + data.message + '</span>'
          );
        }
      })
      .fail(function () {
        $cont.append(
          '<span class="form_error form_error-m">Erreur, veuillez essayer de nouveau.</span>'
        );
      })
      .always(function () {
        requeting = false;
        $cont
          .find('button')
          .removeClass('has-loader')
          .removeClass('has-loader-s')
          .removeClass('has-loader-white')
          .removeClass('is-loading');
        // console.log("complete");
      });
  }

  function login_update_form($cont, data) {
    $main.find('[data-login-remove]').remove();
    $main.find('[data-login-title]').html(data.message);
    for (var prop in data.user) {
      if (data.user.hasOwnProperty(prop)) {
        // console.log(prop,data.user[prop]);
        var $el = $main.find('[data-login-' + prop + ']');
        if ($el.is('[type="checkbox"]')) {
          if (data.user[prop]) {
            $el.get(0).checked = true;
          } else {
            $el.get(0).checked = false;
          }
        } else {
          if (data.user[prop]) {
            $el.val(data.user[prop]);
          } else {
            $el.val('');
          }
        }
        if ($el.is('select')) {
          $el.trigger('change');
        } else {
          $el.trigger('blur');
        }
        if ($el.attr('type') == 'email') {
          // $el.get(0).disabled = true;
          $el.attr('readonly', 'true');
        }
      }
    }
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Select2
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  $body.find('select.js-select2').each(function (index, el) {
    var $el = $(el);
    var required = el.hasAttribute('required');
    var placeholder = el.hasAttribute('data-placeholder')
      ? el.getAttribute('data-placeholder')
      : null;
    var searchPlaceholder = el.hasAttribute('data-search-placeholder')
      ? el.getAttribute('data-search-placeholder')
      : '';
    var $dropdownParent = el.hasAttribute('data-container')
      ? $el.closest('#' + $el.attr('data-container'))
      : $el.closest('form');
    $dropdownParent = $dropdownParent.length ? $dropdownParent : $body;
    // var $fieldCont = $el.closest('.form_field');
    var attrs = {
      dropdownParent: $dropdownParent,
      width: 'style',
      allowClear: !required && !el.hasAttribute('data-dontallowClear'),
      placeholder: placeholder,
      data: [],
      searchInputPlaceholder: searchPlaceholder,
      dropdownCssClass: '',
      // escapeMarkup: function( m ){ return m; }
    };
    if (
      el.hasAttribute('data-no-search') ||
      (el.options.length < 10 && !el.hasAttribute('data-projects'))
    ) {
      attrs.dropdownCssClass = attrs.dropdownCssClass + ' no-search';
    }
    if (el.hasAttribute('data-projects')) {
      // attrs.id = 'id';
      attrs.escapeMarkup = function (markup) {
        return markup;
      };
      attrs.minimumInputLength = 0;
      attrs.templateResult = formatRepo;
      attrs.templateSelection = formatRepoSelection;
      attrs.dropdownCssClass =
        attrs.dropdownCssClass + ' select2-dropdown--projects';
      attrs.ajax = {
        url: url.ajax,
        dataType: 'json',
        delay: 250,
        type: 'post',
        data: function (params) {
          return {
            s2q: params.term, // search term
            s2page: params.page,
            action: 'projects_select_search',
          };
        },
        processResults: function (data, params) {
          // console.log(data);
          params.page = params.page || 1;

          return {
            results: data.items,
            pagination: {
              more: params.page * 30 < data.total_count,
            },
          };
        },
        cache: true,
      };
    } else if (el.hasAttribute('data-select2-filter')) {
      attrs.dropdownCssClass =
        attrs.dropdownCssClass + ' select2-dropdown--filter';
      attrs.containerCssClass = 'select2-container--filter';
      attrs.allowClear = false;
    }
    $el.select2(attrs);
    $el.on('select2:select', function (e) {
      var $target = e.params.originalEvent.originalEvent.target
        ? $(e.params.originalEvent.originalEvent.target)
        : [];
      if ($target.is('a')) {
        e.preventDefault();
        w.open($target.attr('href'), '_blank');
      }
    });
    $el.on('select2:opening', function (e) {
      $(this)
        .closest('.is-error')
        .removeClass('is-error')
        .find('.form_error')
        .remove();
    });
  });

  function formatRepo(repo) {
    if (repo.loading) {
      return repo.text;
    }
    var markup = "<div class='select2-result-project'>";
    markup +=
      ' <a href="' +
      repo.link +
      '" target="_blank">' +
      'En savoir plus' +
      '</a>';
    markup += '<strong>' + repo.title + '</strong>';
    if (repo.city) markup += ' <span>' + repo.city + '</span>';
    if (repo.num_dep) markup += ' <span>(' + repo.num_dep + ')</span>';
    markup += '</div>';
    return markup;
  }

  function formatRepoSelection(repo) {
    var markup = '';
    if (repo.title) markup += '<strong>' + repo.title + '</strong>';
    if (repo.city) markup += ' <span>' + repo.city + '</span>';
    if (repo.num_dep) markup += ' <span>(' + repo.num_dep + ')</span>';
    return markup || repo.text;
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Filtres
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var $filters = $main.find('[data-filter]');
  var $filtersCont = $main.find('[data-ajax-filters]');
  var $filtersReset = $main.find('[data-filter-reset]');
  var $filtersResult = $filtersCont.length
    ? $('#' + $filtersCont.attr('data-ajax-filters'))
    : [];
  var globalFilters = {};

  function get_filters() {
    globalFilters = {};

    if (!$filters.length) return;

    $filters.each(function (idx, filter) {
      var $select = $(filter);
      var selectVal = $select.val();
      if (
        selectVal &&
        $.isNumeric(selectVal) &&
        Math.floor(selectVal) == selectVal
      )
        selectVal = parseInt(selectVal);
      if (selectVal) globalFilters[$select.data('filter')] = selectVal;
    });
  }

  function filters_changing(el) {
    if (requeting) return;

    $filtersCont.find('.filters_wrapper').removeClass('is-open');

    var $el = $(el);
    if ($el.is('select')) {
      if ($el.val() != '')
        $el
          .closest('.filters_i')
          .addClass('has-active')
          .find('[data-filter-reset]')
          .text($el.get(0).options[$el.get(0).selectedIndex].innerHTML);
    }

    get_filters();
    if (projectsMap) {
      maps_set_markers();
    }
    filters_get_link();
  }

  function filters_empty_result() {
    $filtersResult
      .css('height', $filtersResult.outerHeight() + 'px')
      .empty()
      .addClass('has-loader')
      .addClass('has-loader-top')
      .addClass('is-loading');
  }

  function filters_remove_loader_result() {
    $filtersResult
      .removeAttr('style')
      .removeClass('is-loading')
      .removeClass('has-loader-top')
      .removeClass('has-loader');
  }

  function filters_get_link() {
    var ajaxArgs = [];
    for (var filter in globalFilters) {
      if (globalFilters.hasOwnProperty(filter)) {
        ajaxArgs.push(filter + '__' + globalFilters[filter]);
      }
    }

    requeting = true;
    filters_empty_result();

    $.ajax({
      url: url.ajax,
      type: 'GET',
      dataType: 'json',
      data: {
        action: 'get_filters_query_link',
        filters: ajaxArgs.join('____'),
        pt: $filtersResult.data('post-type'),
      },
    })
      .done(function (data) {
        requeting = false;
        if (data.link) {
          if (support.history) {
            history.replaceState({}, '', data.link);
          }
          filters_get_result(data.link);
        } else {
          requeting = false;
          filters_remove_loader_result();
        }
      })
      .fail(function () {
        requeting = false;
        filters_remove_loader_result();
        if (url.page) w.location.href = url.page;
      });
  }

  function filters_get_result(link) {
    if (requeting) return;

    requeting = true;
    $.ajax({
      url: link,
      type: 'GET',
      dataType: 'json',
      data: { is_ajax: '1' },
    })
      .done(function (data) {
        if (data.before) $filtersResult.append(data.before);
        if (data.grid) $filtersResult.append(data.grid);
        if (data.pagination) $filtersResult.append(data.pagination);
        pagination_to_select($filtersResult);
        select_redirect_init($filtersResult);
        fallback_object_fit($filtersResult);
        update_parallax_imgs();
      })
      .fail(function () {})
      .always(function () {
        filters_remove_loader_result();
        requeting = false;
      });
  }

  function filters_reset(el) {
    var $el = $(el);
    var $elParent = $el.closest('.filters_i');
    $elParent
      .removeClass('has-active')
      .find('select')
      .val('')
      .trigger('change');
  }

  if ($filtersResult.length) {
    get_filters();
    $filters.filter('select').on('change', function (event) {
      filters_changing(this);
    });
    $filters
      .filter('input')
      .closest('form')
      .on('submit', function (event) {
        event.preventDefault();
        filters_changing(this);
      });
    $filtersReset.on('click', function (event) {
      event.preventDefault();
      filters_reset(this);
    });
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Redirection avec select
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function select_redirect_init($cont) {
    var $selectsRedirect = ($cont || $main).find('.js-select-redirect');
    $selectsRedirect.on('change', function (event) {
      if ($(this).is('[data-filter]') && $filtersResult.length) return;
      if (
        this.options[this.selectedIndex] &&
        this.options[this.selectedIndex].hasAttribute('data-url')
      )
        w.location.href =
          this.options[this.selectedIndex].getAttribute('data-url');
      else if (this.value != '') w.location.href = this.value;
    });
  }
  select_redirect_init();

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Infobox
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  $main.find('[href^="#infobox-"]').on('click', function (event) {
    event.preventDefault();
    $($(this).attr('href')).slideToggle(400);
  });

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Gestion des maps
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var $maps = $main.find('.gogoMap'),
    maps_styles = [
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#e9e9e9' }, { lightness: 17 }],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [{ color: '#f5f5f5' }, { lightness: 20 }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [{ color: '#ffffff' }, { lightness: 17 }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }, { lightness: 18 }],
      },
      {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }, { lightness: 16 }],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#f5f5f5' }, { lightness: 21 }],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#dedede' }, { lightness: 21 }],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          { visibility: 'on' },
          { color: '#ffffff' },
          { lightness: 16 },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }],
      },
      { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [{ color: '#fefefe' }, { lightness: 20 }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }],
      },
    ];

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Icones
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // Liste des icones
  var maps_icons_path = {
    round: '',
    edifice:
      '<path transform="translate(8.000000, 7.000000)" fill="#FFFFFF" d="M21,21h-5v-8.7l5,1.5V21z M15,21h-1v-4c0-1.1-0.9-2-2-2s-2,0.9-2,2v4H9V11c0-1.7,1.3-3,3-3s3,1.3,3,3V21z M13,21h-2v-4c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1V21z M8,21H3v-7.1l5-1.5V21z M23.5,21H22v-7.5c0-0.2-0.1-0.4-0.4-0.5L16,11.3V11c0-2-1.5-3.7-3.5-4V3H14c0.3,0,0.5-0.2,0.5-0.5S14.3,2,14,2h-1.5V0.5C12.5,0.2,12.3,0,12,0c-0.3,0-0.5,0.2-0.5,0.5V2H10C9.7,2,9.5,2.2,9.5,2.5S9.7,3,10,3h1.5v4.1C9.5,7.3,8,9,8,11v0.3L2.4,13C2.2,13.1,2,13.3,2,13.5V21H0.5C0.2,21,0,21.2,0,21.5C0,21.8,0.2,22,0.5,22h2h6h2h3h2h6h2c0.3,0,0.5-0.2,0.5-0.5C24,21.2,23.8,21,23.5,21L23.5,21z M6,15v1l-1,0l0-1H6z M4.5,17h2C6.8,17,7,16.8,7,16.5v-2C7,14.2,6.8,14,6.5,14h-2C4.2,14,4,14.2,4,14.5v2C4,16.8,4.2,17,4.5,17L4.5,17z M19,15v1l-1,0v-1H19z M17.5,17h2c0.3,0,0.5-0.2,0.5-0.5v-2c0-0.3-0.2-0.5-0.5-0.5h-2c-0.3,0-0.5,0.2-0.5,0.5v2C17,16.8,17.2,17,17.5,17L17.5,17z M12,12c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1C13,11.6,12.6,12,12,12 M12,9c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S13.1,9,12,9"/>',
    pgmf: '<path fill="#FFFFFF" d="M30.5,24h-21C9.2,24,9,23.8,9,23.5S9.2,23,9.5,23h21c0.3,0,0.5,0.2,0.5,0.5S30.8,24,30.5,24L30.5,24z M10,11h7v0.5c0,0.3,0.2,0.5,0.5,0.5h5c0.3,0,0.5-0.2,0.5-0.5V11h7v11H10V11z M18,11v-1h4v1H18z M31,22.1V10.5c0-0.3-0.2-0.5-0.5-0.5H23V9.5C23,9.2,22.8,9,22.5,9h-2V7.5C20.5,7.2,20.3,7,20,7s-0.5,0.2-0.5,0.5V9h-2C17.2,9,17,9.2,17,9.5V10H9.5C9.2,10,9,10.2,9,10.5v11.6c-0.6,0.2-1,0.8-1,1.4C8,24.3,8.7,25,9.5,25h4.7l-2.6,5.3c-0.1,0.2,0,0.5,0.2,0.7c0.1,0,0.1,0.1,0.2,0.1c0.2,0,0.4-0.1,0.4-0.3l2.9-5.7H19v5.5c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V25h4.7l2.9,5.7c0.1,0.2,0.3,0.3,0.4,0.3s0.2,0,0.2-0.1c0.2-0.1,0.3-0.4,0.2-0.7l-2.6-5.3h4.7c0.8,0,1.5-0.7,1.5-1.5C32,22.8,31.6,22.3,31,22.1L31,22.1z"/>',
    promotion:
      '<path transform="translate(12.000000, 8.000000)" fill="#FFFFFF" d="M3.6,25C2.3,25,0,24.3,0,21.5V9c0-0.3,0.2-0.5,0.5-0.5C0.8,8.5,1,8.7,1,9v12.5C1,24,3.4,24,3.5,24L15,22.6V6.1L2.6,7.5h0C1.1,7.5,0,6.4,0,5c0-2,1-2.2,5.4-2.8C7.3,2,9.9,1.6,13.4,1c0.3,0,0.5,0.1,0.6,0.4c0,0.3-0.1,0.5-0.4,0.6c-3.5,0.6-6.1,1-8,1.2C1,3.8,1,3.9,1,5c0,0.8,0.7,1.5,1.5,1.5L15.4,5c0.1,0,0.3,0,0.4,0.1C15.9,5.2,16,5.4,16,5.5V23c0,0.3-0.2,0.5-0.4,0.5L3.6,25L3.6,25z M3,5.5c-0.2,0-0.5-0.2-0.5-0.4c0-0.3,0.2-0.5,0.4-0.6L14.4,3C14.7,3,15,3.2,15,3.4C15,3.7,14.8,4,14.6,4L3,5.5C3,5.5,3,5.5,3,5.5"/>',
  };

  // Icone svg
  function maps_get_icon(type, color) {
    // https://stackoverflow.com/questions/24413766/how-to-use-svg-markers-in-google-maps-api-v3
    var template = [
      '<?xml version="1.0"?>',
      '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40px" height="40px">',
      '<circle fill="{{color}}" cx="20" cy="20" r="20"/>',
      maps_icons_path[type],
      '</svg>',
    ].join('\n');
    var svg = template.replace('{{color}}', color);
    return maps_encode_icon(svg);
  }

  // Icone encodée
  function maps_encode_icon(icon) {
    return 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(icon);
  }

  // Icone google map
  function maps_pinSymbol(color, type) {
    return {
      url: maps_get_icon(type, color),
      scaledSize: new google.maps.Size(40, 40),
    };
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Chargement de google map
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function async(script, callback) {
    var s = document.createElement('script'),
      head = document.getElementsByTagName('head')[0];
    s.type = 'text/javascript';
    s.async = true;
    s.defer = true;
    s.src = script;
    if (typeof callback == 'function') {
      s.addEventListener(
        'load',
        function (e) {
          callback.call();
        },
        false
      );
    }
    head.appendChild(s);
  }

  function maps_init() {
    if (!$maps.length) return;
    var gmapcallback = function () {
      maps_setup();
    };
    window.gmapcallback = gmapcallback;
    async(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyArMqBA5IpxS6cq_kcWewUu1q2pyUS5UPU&callback=gmapcallback'
    );
  }
  maps_init();

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Création des maps
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function maps_setup() {
    if (!$maps.length) return;
    $maps.each(function (idx, gogomap) {
      if ($(gogomap).hasClass('gogoMap-projects')) {
        maps_render_projects(gogomap);
      } else {
        maps_render(gogomap);
      }
    });
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Map classique
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function maps_render(gogomap) {
    var $gogomap = $(gogomap),
      lat = $gogomap.attr('data-lat'),
      lng = $gogomap.attr('data-lng'),
      zoom = $gogomap.attr('data-zoom')
        ? parseInt($gogomap.attr('data-zoom'))
        : 17,
      icon = $gogomap.attr('data-icon')
        ? $gogomap.attr('data-icon')
        : 'edifice',
      etat = $gogomap.attr('data-etat') ? $gogomap.attr('data-etat') : 'non',
      color = maps_get_icon_color(etat),
      // title = $gogomap.attr('data-title'),
      // content = $gogomap.attr('data-content'),
      latlng = new google.maps.LatLng(lat, lng),
      args = {
        zoom: zoom,
        center: latlng,
        // scrollwheel : false,
        gestureHandling: 'cooperative',
        // draggable : true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: maps_styles,
        streetViewControl: false,
        overviewMapControl: false,
        mapTypeControl: false,
        panControl: false,
        // scaleControl : false,
        zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL },
      },
      map = new google.maps.Map(gogomap, args),
      // image = 'img/map-icon.png',
      marker = new google.maps.Marker({
        position: latlng,
        map: map,
        // title : title,
        icon: maps_pinSymbol(color, icon),
        optimized: false,
      });

    // if(content && content != "") {
    //   var contentString = '<div class="gogoMap_content">'+'<strong>'+title+'</strong><div>'+content+'</div>'+'</div>',
    //       infowindow = new google.maps.InfoWindow({
    //         content: contentString
    //       });
    //   google.maps.event.addListener(marker, 'click', function() {
    //     infowindow.open(map,marker);
    //   });
    // }
    google.maps.event.addDomListener(window, 'resize', function () {
      map.setCenter(latlng);
    });
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Map projets
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var projectsMap = null;
  var maps_markerCluster = null;
  var maps_projectsPositions = {};
  var maps_projectsMarkers = null;
  var maps_infowindow = null;

  function maps_render_projects(gogomap) {
    var $gogomap = $(gogomap);

    // Réglages de base
    projectsMap = new google.maps.Map(gogomap, {
      zoom: 15,
      center: new google.maps.LatLng(48.8821238, 2.3341638),
      // scrollwheel : false,
      gestureHandling: 'cooperative',
      // draggable : true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: maps_styles,
      streetViewControl: false,
      overviewMapControl: false,
      mapTypeControl: false,
      panControl: false,
      // scaleControl : false,
      zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL },
    });

    maps_infowindow = new google.maps.InfoWindow({ maxWidth: 205 });
    google.maps.event.addListener(projectsMap, 'click', function () {
      maps_infowindow.close();
    });

    // Récupération de la liste des localisations
    // $.ajax({
    //   url: url.ajax,
    //   type: 'GET',
    //   dataType: 'json',
    //   data: {action: 'projects_map'}
    // })
    // .done(function(data) {
    //   maps_set_markers(data.items);
    // })
    // .fail(function() {
    //   // console.log("error");
    // })
    // .always(function() {
    //   // console.log("complete");
    // });
    $.getJSON(url.json_projects, function (data) {
      for (var itemID in data.items) {
        var item = data.items[itemID];
        maps_projectsPositions[itemID] = {
          title: item.t,
          s: item.s,
          link: url.home + item.lk,
          img: url.upload + item.im,
          lat: item.lat,
          lng: item.lng,
          dont_ext: typeof item.de != 'undefined' && item.de,
          icon: item.ic,
          etat: get_etat_slug(data.items[itemID].e),
          type_name: item.ty,
          projet_type: item.pt,
          departement: item.d,
        };
      }
      // Filtres de la forme : {'etat': 'terminee', 'projet_type': 2, 'departement': 5, 's': 'eglise'}
      maps_set_markers();
    });
  }

  function maps_get_markers() {
    // Création des markers
    var filters = globalFilters;
    var items = maps_projectsPositions ? maps_projectsPositions : {};
    var itemsFiltered = {};
    var arrayReturn = [];
    var itemIsValid = true;
    if (filters) {
      for (var itemID in items) {
        itemIsValid = true;
        if (items.hasOwnProperty(itemID)) {
          for (var filter in filters) {
            if (filters[filter]) {
              if (
                !items[itemID][filter] ||
                ($.isArray(items[itemID][filter]) &&
                  $.inArray(filters[filter], items[itemID][filter]) == -1) ||
                (!$.isArray(items[itemID][filter]) &&
                  filter == 's' &&
                  items[itemID][filter]
                    .toLowerCase()
                    .removeAccents()
                    .indexOf(filters[filter].toLowerCase().removeAccents()) ==
                    -1) ||
                (!$.isArray(items[itemID][filter]) &&
                  filter != 's' &&
                  filters[filter] != items[itemID][filter])
              ) {
                itemIsValid = false;
                break;
              }
            }
          }
          if (itemIsValid) itemsFiltered[itemID] = items[itemID];
        }
      }
    } else {
      itemsFiltered = items;
    }
    for (var itemID in itemsFiltered) {
      if (items.hasOwnProperty(itemID)) {
        var item = items[itemID];
        var color = maps_get_icon_color(item.etat);
        arrayReturn.push(
          new google.maps.Marker({
            id: itemID,
            title: item.title,
            position: { lat: item.lat, lng: item.lng },
            icon: maps_pinSymbol(color, item.icon),
            optimized: false,
            othersDatas: {
              title: item.title,
              s: item.s,
              link: item.link,
              img: item.img,
              etat: item.etat,
              type_name: item.type_name,
              projet_type: item.projet_type,
              departement: item.departement,
              dont_ext: item.dont_ext,
            },
          })
        );
      }
    }
    return arrayReturn;
    // return items.map(function(item, i) {
    //   var color = '#4A4A4A';
    //   if(item.etat == 'en-cours')
    //     color = '#E3042E';
    //   else if(item.etat == 'terminee')
    //     color = '#94B951';
    //   return new google.maps.Marker({
    //     id: item.id,
    //     title: item.title,
    //     position: {lat: item.lat, lng: item.lng},
    //     icon: maps_pinSymbol(color,item.icon),
    //     optimized: false,
    //     othersDatas : {
    // 			'title': item.title,
    // 			'link': item.link,
    // 			'img': item.img,
    // 			'etat': item.etat,
    // 			'type': item.type
    //     }
    //   });
    // });
  }

  function maps_get_icon_color(etat) {
    var color = '#4A4A4A';
    if (etat == 'en-cours') color = '#E3042E';
    else if (etat == 'terminee') color = '#94B951';
    return color;
  }

  function maps_set_markers() {
    if (!projectsMap) return;

    maps_projectsMarkers = maps_get_markers();

    if (!maps_projectsMarkers || !maps_projectsMarkers.length) {
      if (maps_markerCluster) {
        maps_markerCluster.clearMarkers();
      }
      $main.find('.projectsMap').hide();
      return;
    }

    if (!$main.find('.projectsMap').is(':visible')) {
      $main.find('.projectsMap').show();
      google.maps.event.trigger(projectsMap, 'resize');
    }

    // Bounding
    var bounds = new google.maps.LatLngBounds();
    maps_projectsMarkers.forEach(function (marker, idx) {
      if (!marker.othersDatas.dont_ext) bounds.extend(marker.position);
    });
    //4 coordonnées qui représentent la france
    // bounds.extend({lat: 51.112543, lng: 2.415208});
    // bounds.extend({lat: 48.926735, lng: 8.155081});
    // bounds.extend({lat: 42.330828, lng: 2.837698});
    // bounds.extend({lat: 48.345931, lng: -4.764840});
    bounds.extend({ lat: 50.114769, lng: 2.445721 });
    bounds.extend({ lat: 47.141633, lng: 6.421431 });
    bounds.extend({ lat: 43.337669, lng: 1.36772 });
    bounds.extend({ lat: 47.957288, lng: -3.026811 });
    projectsMap.fitBounds(bounds);

    // Cluster
    // https://stackoverflow.com/questions/28178080/getting-google-map-markerclusterer-plus-icons-in-one-color
    var cluster_styles = [
      {
        width: 40,
        height: 40,
        url: maps_get_icon('round', '#005285'),
        textColor: 'white',
        textSize: 12,
      },
    ];
    // https://developers.google.com/maps/documentation/javascript/marker-clustering
    // https://github.com/googlemaps/js-marker-clusterer
    // https://googlemaps.github.io/js-marker-clusterer/docs/reference.html
    // https://github.com/googlemaps/js-marker-clusterer/blob/gh-pages/examples/advanced_example.html
    // supprimer Clic sur markers
    // maps_projectsMarkers.forEach(function(marker, idx) {
    //   google.maps.event.removeListener(marker, 'click', maps_open_info);
    // });
    if (maps_markerCluster) {
      maps_markerCluster.clearMarkers();
    }
    maps_markerCluster = new MarkerClusterer(
      projectsMap,
      maps_projectsMarkers,
      {
        styles: cluster_styles,
      }
    );

    // Clic sur markers
    maps_projectsMarkers.forEach(function (marker, idx) {
      google.maps.event.addListener(marker, 'click', maps_open_info);
    });
  }

  function maps_open_info(e) {
    var marker = this;
    var datas = marker.othersDatas;
    var infoHtml = '<div class="gmInfo">';
    if (datas.img)
      infoHtml +=
        '<p class="gmInfo_img" style="background-image: url(' +
        datas.img +
        ')"></p>';
    if (datas.type_name)
      infoHtml +=
        '<p class="gmInfo_type txt-12 txt-b txt-uc txt-grey-5">' +
        datas.type_name +
        '</p>';
    infoHtml +=
      '<h3 class="gmInfo_title txt-2-18"><a href="' +
      datas.link +
      '" target="_blank">' +
      datas.title +
      '</a></h3>';
    if (datas.etat && datas.etat != 'non')
      infoHtml +=
        '<p class="gmInfo_etat gmInfo_etat-' +
        datas.etat +
        ' txt-12 txt-b txt-uc">' +
        get_etat_name(datas.etat) +
        '</p>';
    infoHtml += '</div>';
    maps_infowindow.setContent(infoHtml);
    maps_infowindow.open(projectsMap, marker);
    // https://stackoverflow.com/questions/18933367/gmaps-v3-infowindow-disable-the-close-x-button
  }

  function get_etat_slug(etat) {
    var etats = {
      n: 'non',
      e: 'en-cours',
      t: 'terminee',
    };
    return etats[etat] ? etats[etat] : 'Fermée';
  }

  function get_etat_name(etat) {
    var etats = {
      n: 'Fermée',
      e: 'Souscription en cours',
      t: 'Souscription terminée',
    };
    return etats[etat] ? etats[etat] : 'Fermée';
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Videos
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var youtubeApiLoaded = false;
  var nextVideoToLoad = false;
  function videos_init(container) {
    $main.find('.js-video a').on('click', video_trigger);
  }
  videos_init();

  function video_trigger(event) {
    event.preventDefault();
    var $video_link = $(this);
    var $video = $video_link.closest('.js-video');
    var videoId = $video.data('id');
    var videoType = $video.data('type');
    var videoUrl = $video.data('url');
    nextVideoToLoad = {
      container: $video,
      id: videoId,
      type: videoType,
      url: videoUrl,
      img: $video.find('img'),
    };
    $video_link.off('click', video_trigger);
    if (videoType == 'youtube') {
      if (youtubeApiLoaded) {
        onYouTubeIframeAPIReady();
      } else {
        youtubeApiLoaded = true;
        var tag = d.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = d.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
    } else if (videoType == 'vimeo') {
      var url =
        'http://www.vimeo.com/api/oembed.json' +
        '?url=' +
        encodeURIComponent(videoUrl) +
        '&callback=' +
        'onVimeoOembedReady' +
        '&autoplay=1';
      var tag = d.createElement('script');
      tag.src = url;
      var firstScriptTag = d.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
    $video_link.remove();
    $video
      .addClass('has-loader')
      .addClass('has-loader-white')
      .addClass('is-loading');
  }

  w.onYouTubeIframeAPIReady = function () {
    if (!nextVideoToLoad) return;
    var videoContainer = d.createElement('div');
    nextVideoToLoad.container.append(videoContainer);
    new YT.Player(videoContainer, {
      videoId: nextVideoToLoad.id,
      playerVars: {
        rel: false,
        html5: true,
        autohide: true,
      },
      events: {
        onReady: onPlayerReady,
      },
    });
    video_ready();
  };

  w.onVimeoOembedReady = function (video) {
    if (!nextVideoToLoad) return;
    nextVideoToLoad.container.append(unescape(video.html));
    video_ready();
  };

  function onPlayerReady(event) {
    event.target.playVideo();
  }

  function video_ready() {
    if (!nextVideoToLoad) return;
    nextVideoToLoad = false;
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Plyr
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var plyr_is_ready = false;
  var plyr_js_is_ready = false;
  var plyr_css_is_ready = false;
  var plyr_js_is_loading = false;
  var plyr_css_is_loading = false;
  var $videosIframes = [];
  $main
    .find('.media.is-type-video iframe[src]')
    .wrap('<div class="js-plyr"></div>');

  function init_videos() {
    if (!$videosIframes.length) return;
    video_load_plyr();
  }

  function reinit_videos() {
    $videosIframes = $main.find('.js-plyr');
    init_videos();
  }
  reinit_videos();

  function video_load_plyr() {
    if (plyr_is_ready) {
      init_plyr_on_video();
      return;
    }

    if (!plyr_js_is_loading) {
      plyr_js_is_loading = true;
      async(url.theme + 'js/vendor/plyr.min.js', plyr_js_loaded);
    }
    if (!plyr_css_is_loading) {
      plyr_css_is_loading = true;
      loadCSS(url.theme + 'js/vendor/plyr.css', plyr_css_loaded);
    }
  }

  function plyr_js_loaded() {
    plyr_js_is_ready = true;
    check_plyr_is_ready();
  }

  function plyr_css_loaded() {
    plyr_css_is_ready = true;
    check_plyr_is_ready();
  }

  function check_plyr_is_ready() {
    if (!plyr_is_ready && plyr_js_is_ready && plyr_css_is_ready) {
      plyr_is_ready = true;
      init_plyr_on_video();
    }
    return plyr_is_ready;
  }

  function init_plyr_on_video() {
    // $videosIframes.each(function(idx, el) {
    //   if($(el).hasClass('iframe')) {
    //     $(el).addClass('plyr__video-embed').removeClass('iframe').children('span').remove();
    //   }
    // });
    // https://github.com/sampotts/plyr
    Plyr.setup('.page_header_video .js-plyr', {
      muted: true,
      controls: false,
      clickToPlay: false,
      autoplay: true,
      autopause: false,
      loop: {
        active: true,
      },
    });
    Plyr.setup('#main .js-plyr', {
      controls: [
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'fullscreen',
      ], //'play-large',
    });
    // $.each(players, function (idx, el) {
    //   var $customOverlay = $(
    //     '<button class="plyr__customOverlay" type="button"><span class="plyr__customOverlay__play f1-46"><span class="plyr__customOverlay__playTxt">Play</span><span class="plyr__customOverlay__playIcon icon-play"></span></span> ' +
    //       ($(el.elements.container).find('iframe').attr('title')
    //         ? '<span class="plyr__customOverlay__title f1-46">' +
    //           $(el.elements.container).find('iframe').attr('title') +
    //           '</span>'
    //         : '') +
    //       '</button>'
    //   );
    //   $(el.elements.container).append($customOverlay);
    //   $customOverlay.on('click', function (event) {
    //     event.preventDefault();
    //     $(this)
    //       .parent()
    //       .find('.plyr__controls [data-plyr="play"]')
    //       .trigger('click');
    //   });
    // });
  }

  d.addEventListener('lazybeforeunveil', function (e) {
    var $el = $(e.target);
    if (!$el.is('iframe')) return;
    // console.log($el.attr('title'));
    $el.attr('src', $el.attr('data-src'));
    if ($el.closest('.media.is-type-video').length) {
      $el.wrap('<div class="js-plyr"></div>');
    }
    reinit_videos();
    e.preventDefault();
  });

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Parallax mobile pour les archives
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var $parallaxImg = [];
  var parallaxActive = false;

  function update_parallax_imgs() {
    $parallaxImg = $main.find(
      '.archives .modP_img img, .archives .tabList_img img'
    );
    check_if_parallax_is_active();
  }
  update_parallax_imgs();

  function update_parallax() {
    if (!parallaxActive) return;
    $parallaxImg.each(function (index, el) {
      var $img = $(el);
      var $imgCont = $(el).parent();
      var offsetTop = $imgCont.get(0).getBoundingClientRect().top;
      var contH = $imgCont.outerHeight();
      if (offsetTop + contH > 0 && offsetTop < windowH) {
        var ratioH = $img.outerHeight() / contH;
        var pcTransMax = 100 / ratioH - 100;
        var ratioTrans = offsetTop / windowH;
        el.style[transformProp] =
          'translateY(' + pcTransMax * ratioTrans + '%)';
      }
    });
  }

  function is_parallax_active() {
    return (
      $parallaxImg.length &&
      Math.abs($parallaxImg.first().outerWidth() - windowW) < 10
    );
  }

  function check_if_parallax_is_active() {
    var new_parallaxActive = is_parallax_active();
    if (new_parallaxActive != parallaxActive && !new_parallaxActive)
      reset_parallax();
    parallaxActive = new_parallaxActive;
    if (parallaxActive) update_parallax();
  }

  function reset_parallax() {
    $parallaxImg.each(function (index, el) {
      $(el).removeAttr('style');
    });
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Keys event
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // $html.on( "keydown", function( event ) {
  //   // event.which (27: echap, etc.)
  //   if(event.which == 27) {
  //     //...
  //   }
  // });

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //On resize
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  $window
    .on('resize', function (event) {
      update_dimensions();
      check_if_parallax_is_active();
    })
    .trigger('resize');

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //On scroll
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var ticking = false;
  $window
    .on('scroll', function (event) {
      update_scroll();
      requestTick();
    })
    .trigger('scroll');

  function requestTick() {
    if (!ticking) {
      ticking = true;
      w.requestAnimationFrame(trigger_scroll);
    }
  }

  function trigger_scroll() {
    update_header();
    update_parallax();
    ticking = false;
  }
})(jQuery);

//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
//Files
//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
(function ($) {
  var w = window,
    d = document,
    $window = $(w),
    $html = $('html'),
    $body = $('body'),
    $main = $('#main');

  if (!constants.aide_id || !$main.find('.js-ajax-file').first().length) return;

  $main.on('change', '.js-ajax-file', function (event) {
    var file = event.target.files[0];
    if (!file) return;
    uploadFiles(this, file);
  });

  $main.find('.file_remove').one('click', removeFile);

  function uploadFiles(input, file) {
    var $input = $(input);
    var max_size = $input.attr('data-size') || constants.max_upload_size_octets;
    var max_size_format =
      $input.attr('data-size-format') || constants.max_upload_size_format;
    var $container = $input.closest('.file');
    var $label = $container.find('.file_add');
    var fieldname = $container.data('field-name');
    var extensions = $container.data('ext').split(',') || [
      'pdf',
      'doc',
      'docx',
      'odt',
      'jpg',
      'jpeg',
      'png',
    ];
    var data = new FormData();
    data.append('file', file);
    data.append('action', 'ajax_file_upload');
    data.append('post_id', constants.aide_id);
    data.append('field_name', fieldname);

    // On supprime les éventuelles anciennes erreurs
    $container.find('.file_error').remove();

    // Extensions pdf, doc, docx, odt, jpg, jpeg, png
    if ($.inArray(file.name.split('.').pop().toLowerCase(), extensions) < 0) {
      showError(
        $label,
        'Erreur : ' + 'Le fichier doit être au format ' + extensions.join(', ')
      );
      return;
    }

    // Taille < 5Mo
    if (file.size > max_size) {
      showError(
        $label,
        'Erreur : ' + 'Le fichier doit faire moins de ' + max_size_format
      );
      return;
    }

    $label
      .addClass('has-loader')
      .addClass('is-loading')
      .addClass('has-loader-s')
      .addClass('has-loader-white');
    $.ajax({
      url: url.ajax,
      type: 'POST',
      data: data,
      cache: false,
      dataType: 'json',
      processData: false, // Don't process the files
      contentType: false, // Set content type to false as jQuery will tell the server its a query string request
    })
      .done(function (data) {
        if (data.success) {
          var $newFile = $('<span class="file_i"></span>');
          var $removeLink = $(
            '<a href="#" class="file_remove "><span>Supprimer</span></a>'
          );
          $newFile.append(
            '<span class="file_filename">' +
              data.success.split(/[\\/]/).pop() +
              '</span>'
          );
          $newFile.append(
            '<a href="' +
              data.success +
              '" target="_blank" class="file_see"><span>Consulter</span></a>'
          );
          $newFile.append($removeLink);
          $label.before($newFile);
          $removeLink.one('click', removeFile);
        } else {
          showError($label, 'Erreur : ' + data.errors.join('<br>'));
        }
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        showError($label, 'Erreur : ' + textStatus);
      })
      .always(function () {
        $label
          .removeClass('has-loader')
          .removeClass('is-loading')
          .removeClass('has-loader-s')
          .removeClass('has-loader-white');
      });
  }

  function removeFile(event) {
    event.preventDefault();
    var $this = $(this);
    var $container = $this.closest('.file');
    var $row = $this.closest('.file_i');
    var $label = $container.find('.file_add');
    var fieldname = $container.data('field-name');
    var filename = $row.find('.file_filename').text();
    var data = new FormData();
    data.append('action', 'ajax_file_remove');
    data.append('post_id', constants.aide_id);
    data.append('field_name', fieldname);
    data.append('oldfilename', filename);
    $row.remove();
    $label
      .addClass('has-loader')
      .addClass('is-loading')
      .addClass('has-loader-s')
      .addClass('has-loader-white');
    $.ajax({
      url: url.ajax,
      type: 'POST',
      data: data,
      cache: false,
      dataType: 'json',
      processData: false, // Don't process the files
      contentType: false, // Set content type to false as jQuery will tell the server its a query string request
    })
      .done(function (data) {
        if (data.success) {
          // OK
        } else {
          // console.log(data.errors);
        }
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        // console.log(textStatus);
      })
      .always(function () {
        $label
          .removeClass('has-loader')
          .removeClass('is-loading')
          .removeClass('has-loader-s')
          .removeClass('has-loader-white');
      });
  }

  function showError($label, error) {
    $label.after('<span class="file_error txt-red">' + error + '</span>');
  }
})(jQuery);

window.recaptchaInvisibleOnLoad = function () {
  grecaptcha.execute();
};
